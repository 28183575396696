<template>
  <div>
    <div v-if="playground && yieldSettingsPlayground.PriceParts.length === 0">
      <article class="message is-info">
        <div class="message-body">
          Enter at least one price part to see the results.
        </div>
      </article>
    </div>

    <table
      v-if="playground && yieldSettingsPlayground.PriceParts.length > 0"
      class="table is-fullwidth is-bordered is-striped is-narrow is-hoverable"
    >
      <thead>
        <tr>
          <th>&nbsp;</th>
          <th
            v-for="(day, dayIndex) in yieldSettingsPlayground.DayPercentages"
            :key="dayIndex"
            class=" has-text-centered is-size-6"
          >
            {{ day.Day | getDayName }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="pax in playground" :key="pax.Pax">
          <td>
            <span class="is-size-7">pax: </span>
            <span class="has-text-weight-bold">{{ pax.Pax }}</span>
          </td>
          <td>
            <div v-if="mShowAveragePrice">
              <span v-if="showPriceAsTotal">{{
                (getAveragePrice(pax.Days[0].Percentages) * pax.Pax)
                  | toCurrency
              }}</span>
              <span v-if="!showPriceAsTotal">{{
                getAveragePrice(pax.Days[0].Percentages) | toCurrency
              }}</span>
            </div>
            <template v-if="!mShowAveragePrice">
              <div
                v-for="percentage in pax.Days[0].Percentages"
                :key="percentage.Percentage"
                class="percentageRow"
              >
                <span class="is-size-7">{{ percentage.Percentage }}%</span>
                <span v-if="showPriceAsTotal" class="is-size-7 has-text-right">
                  {{
                    (percentage.Price.CalculatedPrice * pax.Pax) | toCurrency
                  }}
                </span>
                <span v-if="!showPriceAsTotal" class="is-size-7 has-text-right">
                  {{ percentage.Price.CalculatedPrice | toCurrency }}
                </span>
              </div>
            </template>
          </td>
          <td>
            <div v-if="mShowAveragePrice">
              <span v-if="showPriceAsTotal">{{
                (getAveragePrice(pax.Days[1].Percentages) * pax.Pax)
                  | toCurrency
              }}</span>
              <span v-if="!showPriceAsTotal">{{
                getAveragePrice(pax.Days[1].Percentages) | toCurrency
              }}</span>
            </div>
            <template v-if="!mShowAveragePrice">
              <div
                v-for="percentage in pax.Days[1].Percentages"
                :key="percentage.Percentage"
                class="percentageRow"
              >
                <span class="is-size-7">{{ percentage.Percentage }}%</span>
                <span v-if="showPriceAsTotal" class="is-size-7 has-text-right">
                  {{
                    (percentage.Price.CalculatedPrice * pax.Pax) | toCurrency
                  }}
                </span>
                <span v-if="!showPriceAsTotal" class="is-size-7 has-text-right">
                  {{ percentage.Price.CalculatedPrice | toCurrency }}
                </span>
              </div>
            </template>
          </td>
          <td>
            <div v-if="mShowAveragePrice">
              <span v-if="showPriceAsTotal">{{
                (getAveragePrice(pax.Days[2].Percentages) * pax.Pax)
                  | toCurrency
              }}</span>
              <span v-if="!showPriceAsTotal">{{
                getAveragePrice(pax.Days[2].Percentages) | toCurrency
              }}</span>
            </div>
            <template v-if="!mShowAveragePrice">
              <div
                v-for="percentage in pax.Days[2].Percentages"
                :key="percentage.Percentage"
                class="percentageRow"
              >
                <span class="is-size-7">{{ percentage.Percentage }}%</span>
                <span v-if="showPriceAsTotal" class="is-size-7 has-text-right">
                  {{
                    (percentage.Price.CalculatedPrice * pax.Pax) | toCurrency
                  }}
                </span>
                <span v-if="!showPriceAsTotal" class="is-size-7 has-text-right">
                  {{ percentage.Price.CalculatedPrice | toCurrency }}
                </span>
              </div>
            </template>
          </td>
          <td>
            <div v-if="mShowAveragePrice">
              <span v-if="showPriceAsTotal">{{
                (getAveragePrice(pax.Days[3].Percentages) * pax.Pax)
                  | toCurrency
              }}</span>
              <span v-if="!showPriceAsTotal">{{
                getAveragePrice(pax.Days[3].Percentages) | toCurrency
              }}</span>
            </div>
            <template v-if="!mShowAveragePrice">
              <div
                v-for="percentage in pax.Days[3].Percentages"
                :key="percentage.Percentage"
                class="percentageRow"
              >
                <span class="is-size-7">{{ percentage.Percentage }}%</span>
                <span v-if="showPriceAsTotal" class="is-size-7 has-text-right">
                  {{
                    (percentage.Price.CalculatedPrice * pax.Pax) | toCurrency
                  }}
                </span>
                <span v-if="!showPriceAsTotal" class="is-size-7 has-text-right">
                  {{ percentage.Price.CalculatedPrice | toCurrency }}
                </span>
              </div>
            </template>
          </td>
          <td>
            <div v-if="mShowAveragePrice">
              <span v-if="showPriceAsTotal">{{
                (getAveragePrice(pax.Days[4].Percentages) * pax.Pax)
                  | toCurrency
              }}</span>
              <span v-if="!showPriceAsTotal">{{
                getAveragePrice(pax.Days[4].Percentages) | toCurrency
              }}</span>
            </div>
            <template v-if="!mShowAveragePrice">
              <div
                v-for="percentage in pax.Days[4].Percentages"
                :key="percentage.Percentage"
                class="percentageRow"
              >
                <span class="is-size-7">{{ percentage.Percentage }}%</span>
                <span v-if="showPriceAsTotal" class="is-size-7 has-text-right">
                  {{
                    (percentage.Price.CalculatedPrice * pax.Pax) | toCurrency
                  }}
                </span>
                <span v-if="!showPriceAsTotal" class="is-size-7 has-text-right">
                  {{ percentage.Price.CalculatedPrice | toCurrency }}
                </span>
              </div>
            </template>
          </td>
          <td>
            <div v-if="mShowAveragePrice">
              <span v-if="showPriceAsTotal">{{
                (getAveragePrice(pax.Days[5].Percentages) * pax.Pax)
                  | toCurrency
              }}</span>
              <span v-if="!showPriceAsTotal">{{
                getAveragePrice(pax.Days[5].Percentages) | toCurrency
              }}</span>
            </div>
            <template v-if="!mShowAveragePrice">
              <div
                v-for="percentage in pax.Days[5].Percentages"
                :key="percentage.Percentage"
                class="percentageRow"
              >
                <span class="is-size-7">{{ percentage.Percentage }}%</span>
                <span v-if="showPriceAsTotal" class="is-size-7 has-text-right">
                  {{
                    (percentage.Price.CalculatedPrice * pax.Pax) | toCurrency
                  }}
                </span>
                <span v-if="!showPriceAsTotal" class="is-size-7 has-text-right">
                  {{ percentage.Price.CalculatedPrice | toCurrency }}
                </span>
              </div>
            </template>
          </td>
          <td>
            <div v-if="mShowAveragePrice">
              <span v-if="showPriceAsTotal">{{
                (getAveragePrice(pax.Days[6].Percentages) * pax.Pax)
                  | toCurrency
              }}</span>
              <span v-if="!showPriceAsTotal">{{
                getAveragePrice(pax.Days[6].Percentages) | toCurrency
              }}</span>
            </div>
            <template v-if="!mShowAveragePrice">
              <div
                v-for="percentage in pax.Days[6].Percentages"
                :key="percentage.Percentage"
                class="percentageRow"
              >
                <span class="is-size-7">{{ percentage.Percentage }}%</span>
                <span v-if="showPriceAsTotal" class="is-size-7 has-text-right">
                  {{
                    (percentage.Price.CalculatedPrice * pax.Pax) | toCurrency
                  }}
                </span>
                <span v-if="!showPriceAsTotal" class="is-size-7 has-text-right">
                  {{ percentage.Price.CalculatedPrice | toCurrency }}
                </span>
              </div>
            </template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    playground: {
      type: Array,
      default: function() {
        return []
      },
    },

    showAveragePrice: {
      type: Boolean,
      default: false,
    },

    showPriceAsTotal: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      mShowAveragePrice: this.showAveragePrice,
    }
  },

  computed: {
    ...mapState('yieldStore', ['yieldSettingsPlayground']),
  },

  watch: {
    showAveragePrice: {
      immediate: true,
      handler: function(newVal, oldVal) {
        this.mShowAveragePrice = newVal
      },
    },
  },

  methods: {
    getAveragePrice(percentages) {
      let total = 0
      let average = 0

      for (let i = 0; i < percentages.length; i++) {
        total = total + percentages[i].Price.CalculatedPrice
      }

      if (total > 0) {
        average = this.roundToTwo(total / percentages.length)
      }

      return average
    },

    roundToTwo(num) {
      return +(Math.round(num + 'e+2') + 'e-2')
    },
  },
}
</script>

<style lang="scss" scoped>
.table.is-narrow tr {
  th {
    padding-left: 0;
    padding-right: 0;
  }
}
tbody {
  tr {
    td {
      div.percentageRow {
        display: flex;
        justify-content: space-between;
        span {
          // &:nth-last-child {
          // }
        }
      }
    }
  }
}
</style>
